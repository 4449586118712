importScripts("https://www.gstatic.com/firebasejs/5.7.0/firebase-app.js")
importScripts("https://www.gstatic.com/firebasejs/5.7.0/firebase-messaging.js")

var config = {
    apiKey: "AIzaSyBDrrZLCVynHi6uYdcgysHGut6ryDYdxnI",
    authDomain: "lowrate-insurance.firebaseapp.com",
    databaseURL: "https://lowrate-insurance.firebaseio.com",
    projectId: "lowrate-insurance",
    storageBucket: "lowrate-insurance.appspot.com",
    messagingSenderId: "661180645585"
};
firebase.initializeApp(config);

var messaging = new firebase.messaging();

// messaging.setBackgroundMessageHandler(function (payload) {
//     var data = payload.data;
//     console.log('[firebase-messaging-sw.js] Received background message ', payload);
//     // var activityData = JSON.parse(data.activityData);
//     // var message = ''
//     // var title = ''
//     // switch (activityData.ActivityTypeId) {

//     //     case 1:
//     //         message = `${activity.TargetedToName} missed the call from ${activity.CreatedByName}`;
//     //         title = 'Missed call!';
//     //         break;

//     //     case 2:
//     //         message = `${activity.TargetedToName} received a call from ${activity.CreatedByName}`;
//     //         title = 'Received call!'
//     //         break;

//     //     case 3:
//     //         message = `${activity.CreatedByName} made a call to ${activity.TargetedToName}`;
//     //         title = 'Made call!'
//     //         break;

//     //     case 4:
//     //         message = `${activity.CreatedByName} uploaded a document ${activity.Description} for ${activity.TargetedToName}`;
//     //         title = 'Upload document!'
//     //         break;

//     //     case 6:
//     //         message = `${activity.CreatedByName} uploaded a signature document ${activity.Description} for ${activity.TargetedToName}`;
//     //         title = 'Upload signature document!'
//     //         break;

//     //     case 8:
//     //         message = `${activity.CreatedByName} signed a documnet ${activity.Description}.`;
//     //         title = 'Sign document!'
//     //         break;

//     //     case 8:
//     //         message = `${activity.CreatedByName} made a payment of ${activity.Description}.`;
//     //         title = 'Made payment!'
//     //         break;

//     // }

//     // // Customize notification here
//     // var notificationTitle = title;//'Background Message Title';
//     // var notificationOptions = {
//     //     body: message,
//     //     title: title,
//     //     onClick: function () {
//     //         window.focus();
//     //         this.close();
//     //     }
//     // };

//     // return self.registration.showNotification(notificationTitle,
//     //     notificationOptions);
// });